const settingCategories = [
  { id: 'intake', name: 'Intake' },
  { id: 'hxManager', name: 'HxManager' },
  {
    id: 'api',
    name: 'Api',
    subCategories: [
      { id: 'accuro', name: 'Accuro' },
      { id: 'canvas', name: 'Canvas' },
      { id: 'epic', name: 'Epic' },
      { id: 'notification', name: 'Notification' },
      { id: 'pushToEndpoint', name: 'PushToEndpoint' },
      { id: 'pcc', name: 'PointClickCare' },
      { id: 'sftp', name: 'SFTP' },
      { id: 'athena', name: 'Athena' },
    ],
  },
  { id: 'geography', name: 'Geography' },
  { id: 'medical', name: 'Medical' },
];

export default settingCategories;
