import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import {
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from '@mui/material';
import useTable from '../../../components/useTable';
import AthenaAppointmentTypeConfigurationModal from './AthenaAppointmentTypeConfigurationModal';

const header = [
  { id: 'id', label: 'Provider ID' },
  { id: 'daysBeforeAppointment', label: 'Days before appointment' },
  { id: 'appointmentTypes', label: 'Appointment type' },
  { id: 'edit', label: '' },
];

export default function AthenaProviderTable(props) {
  const {
    athenaSettings,
    apiClient,
    onSave,
  } = props;

  const [isTypeConfigurationModalOpen, setIsTypeConfigurationModalOpen] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [availableIntakePlans, setAvailableIntakePlans] = useState(null);
  const providers = JSON.parse(athenaSettings.providers);
  const visibleHeader = useMemo(() => header, []);
  const {
    TblContainer,
    TblHead,
  } = useTable(providers, visibleHeader, { fn: (items) => items });

  // remove provider from the list
  const handleDelete = (providerIdToDelete) => {
    delete providers[providerIdToDelete];
    onSave('athenaProviders', 'api', JSON.stringify(providers));
  };

  const getIntakePlans = async () => {
    try {
      const intakePlanList = (await apiClient.getIntakePlans()).data;
      return intakePlanList;
    } catch (error) {
      return [];
    }
  };
  useEffect(() => {
    if (!availableIntakePlans) {
      getIntakePlans().then((intakePlanList) => {
        const { intakePlans } = athenaSettings;
        const filteredPlans = intakePlanList.filter((plan) => intakePlans.split(',').includes(plan.id.toString()));
        setAvailableIntakePlans(filteredPlans);
      });
    }
  }, []);

  return (
    <>
      <TblContainer>
        <TblHead />
        <TableBody>
          {providers && Object.entries(providers).map(([providerId, providerInfo]) => (
            <TableRow
              key={providerId}
            >
              <TableCell>{`${providerInfo.firstName} ${providerInfo.lastName}`}</TableCell>
              <TableCell>{providerInfo.daysBeforeAppointment}</TableCell>
              <TableCell>
                { providerInfo.appointmentTypes
                  ? Object.values(providerInfo.appointmentTypes).map((typeInfo) => `[${typeInfo.name}] : ${typeInfo.intakePlanName}`)
                  : null }
              </TableCell>
              <TableCell>
                <IconButton
                  onClick={() => {
                    setSelectedProvider({ providerId, ...providerInfo });
                    setIsTypeConfigurationModalOpen(true);
                  }}
                >
                  <PencilIcon className="table-tool-icon" />
                </IconButton>
                <IconButton onClick={() => handleDelete(providerId)}>
                  <TrashIcon className="table-tool-icon" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TblContainer>
      <AthenaAppointmentTypeConfigurationModal
        provider={selectedProvider}
        isOpen={isTypeConfigurationModalOpen}
        apiClient={apiClient}
        athenaSettings={athenaSettings}
        handleClose={() => setIsTypeConfigurationModalOpen(false)}
        availableIntakePlans={availableIntakePlans}
        onSave={onSave}
      />
    </>
  );
}

AthenaProviderTable.propTypes = {
  athenaSettings: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    departmentId: PropTypes.string,
    practiceId: PropTypes.string,
    providers: PropTypes.string,
    intakePlans: PropTypes.string,
  }).isRequired,
  apiClient: PropTypes.shape({
    getAthenaAppointmentType: PropTypes.func.isRequired,
    getIntakePlans: PropTypes.func.isRequired,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
};
