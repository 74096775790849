import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box, Chip, Grid, MenuItem,
  Switch,
  Typography,
} from '@mui/material';
import { useApiClient } from '../../../context/ApiClientContext';
import { Controls } from '../../../components/controls/Controls';
import AthenaProviderTable from './AthenaProviderTable';
import AthenaProviderConfigurationModal from './AthenaProviderConfigurationModal';

export default function AthenaSettingPane(props) {
  const { athenaSettings, onSave } = props;
  const [departmentState, setDepartmentState] = useState({
    departments: [],
    selectedDepartment: null,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { siteId } = useParams();
  const apiClient = useApiClient();

  const fetchDepartments = async () => {
    try {
      const response = await apiClient.getAthenaDepartments(siteId, athenaSettings.practiceId);
      setDepartmentState({
        departments: response.data,
        selectedDepartment: response.data.find(
          (department) => department.departmentid === athenaSettings.departmentId,
        ),
      });
    } catch (error) {
      setDepartmentState({});
    }
  };

  useEffect(() => {
    if (siteId && athenaSettings.practiceId) {
      fetchDepartments();
    }
  }, [siteId]);

  const handleDepartmentChange = (event) => {
    const newSelectedDepartment = event.target.value;
    setDepartmentState((prev) => ({
      ...prev,
      selectedDepartment: newSelectedDepartment,
    }));
    onSave('athenaDepartmentId', 'api', newSelectedDepartment.departmentid);
    onSave('athenaProviders', 'api', '{}'); // reset provider configuration if department is changed
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  if (!athenaSettings.practiceId) {
    return <div>Please configure practiceId in customer setting</div>;
  }

  return departmentState.departments && departmentState.departments.length === 0 ? (
    <div>Loading...</div>
  ) : (
    <Box sx={{ margin: '16px' }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid
          item
          alignItems="row"
        >
          <Typography
            variant="body1" // Adjust based on your design (e.g., body1, subtitle1)
          >
            Automation On/Off
            <Switch
              checked={athenaSettings.enabled}
              onChange={(event) => {
                onSave('athenaEnabled', 'api', event.target.checked.toString());
              }}
              name="Athena enabled"
            />
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            display: 'flex',
            gap: '16px', // Space between the Select and Button
            alignItems: 'center',
          }}
        >
          <Controls.Select
            sx={{ minWidth: '200px', maxWidth: '400px' }}
            value={departmentState.selectedDepartment}
            onChange={handleDepartmentChange}
            renderValue={() => {
              if (!departmentState.selectedDepartment) {
                return <em>Please select department</em>;
              }
              return (
                <Chip
                  key={departmentState.selectedDepartment.departmentid}
                  label={departmentState.selectedDepartment.name}
                />
              );
            }}
          >
            {departmentState.departments.map((option) => (
              <MenuItem key={option.departmentid} value={option}>
                {option.name}
              </MenuItem>
            ))}
          </Controls.Select>
          <Controls.Button
            text="Add Provider"
            onClick={handleOpenModal}
          />
        </Grid>
      </Grid>
      <AthenaProviderTable
        athenaSettings={athenaSettings}
        apiClient={apiClient}
        onSave={onSave}
      />
      <AthenaProviderConfigurationModal
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        athenaSettings={athenaSettings}
        apiClient={apiClient}
        onSave={onSave}
      />
    </Box>
  );
}

AthenaSettingPane.propTypes = {
  onSave: PropTypes.func.isRequired,
  athenaSettings: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    departmentId: PropTypes.string,
    practiceId: PropTypes.string,
    providers: PropTypes.string,
    intakePlans: PropTypes.string,
  }).isRequired,
};
