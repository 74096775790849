import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import Modal from '../components/Modal';

function QRCodeButton({ siteId }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isMobile = useMediaQuery('(max-width:425px)');
  const INTAKE_FRONTEND_URL = process.env.REACT_APP_INTAKE_FRONTEND_URL;

  const url = `${INTAKE_FRONTEND_URL}/?siteId=${siteId}`;
  return (
    <>
      <Button variant="outlined" onClick={handleOpen} aria-label="Show QR Code" sx={{ ml: isMobile ? 0 : 2 }}>
        Show QR Code
      </Button>
      <Modal
        isOpen={open}
        handleClose={handleClose}
        title="QR code for intake form"
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <QRCode value={url} size={192} />
        </div>
      </Modal>
    </>
  );
}

QRCodeButton.propTypes = {
  siteId: PropTypes.string.isRequired,
};

export default QRCodeButton;
