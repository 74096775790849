import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box, Chip, Grid, MenuItem,
} from '@mui/material';

import PropTypes from 'prop-types';
import Modal from '../../../components/Modal';
import { Controls } from '../../../components/controls/Controls';
import Form, { useForm } from '../../../components/useForm';

const initialFieldValues = {
  provider: null,
  daysBeforeAppointment: '',
};

export default function AthenaProviderConfigurationModal(props) {
  const {
    onSave,
    isOpen,
    handleClose,
    athenaSettings,
    apiClient,
  } = props;
  const [providers, setProviders] = useState([]);
  const { siteId } = useParams();

  const searchProviders = async () => {
    const { practiceId } = athenaSettings;
    const response = await apiClient.getAthenaProviders(siteId, practiceId);
    setProviders(response.data);
  };

  const {
    values,
    setValues,
    handleInputChange,
  } = useForm(initialFieldValues);

  const makeProviderLabel = (provider) => `${provider.firstname} ${provider.lastname}`;
  useEffect(() => {
    searchProviders();
  }, [athenaSettings]);

  const handleSave = () => {
    const setting = JSON.parse(athenaSettings.providers);
    const providerId = values.provider.providerid.toString();
    const newProviderConf = {
      lastName: values.provider.lastname.toString(),
      firstName: values.provider.firstname.toString(),
      daysBeforeAppointment: values.daysBeforeAppointment,
      appointmentTypes: {},
    };
    setting[providerId] = newProviderConf;
    onSave('athenaProviders', 'api', JSON.stringify(setting));
  };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={() => {
        handleClose();
        setValues({});
      }}
      size="sm"
      title="Provider configuration"
    >
      <Form>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          rowSpacing={2}
        >
          <Controls.Select
            sx={{ minWidth: '200px', maxWidth: '400px' }}
            value={values.provider}
            name="provider"
            onChange={handleInputChange}
            label="Please select provider"
            renderValue={() => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {values.provider ? (
                  <Chip
                    key={values.provider.providerid}
                    label={makeProviderLabel(values.provider)}
                  />
                ) : null}
              </Box>
            )}
          >
            {providers.map((provider) => (
              <MenuItem key={provider.providerid} value={provider}>
                {makeProviderLabel(provider)}
              </MenuItem>
            ))}
          </Controls.Select>
          <Controls.Input
            sx={{ minWidth: '200px', maxWidth: '400px' }}
            label="daysBeforeAppointment"
            name="daysBeforeAppointment"
            value={values.daysBeforeAppointment}
            onChange={handleInputChange}
          />
          <Controls.Button
            text="save"
            onClick={() => {
              handleSave();
            }}
          />
        </Grid>
      </Form>
    </Modal>
  );
}

AthenaProviderConfigurationModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  apiClient: PropTypes.shape({
    getAthenaProviders: PropTypes.func.isRequired,
    getAthenaAppointmentType: PropTypes.func.isRequired,
  }).isRequired,
  athenaSettings: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    departmentId: PropTypes.string,
    practiceId: PropTypes.string,
    providers: PropTypes.string,
  }).isRequired,
};
