import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid, IconButton, MenuItem, TableCell, TableRow,
} from '@mui/material';
import { TrashIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import Modal from '../../../components/Modal';
import { Controls } from '../../../components/controls/Controls';
import useTable from '../../../components/useTable';
import Form, { useForm } from '../../../components/useForm';

const makeIntakePlanLabel = (refCode, moduleName) => `${refCode} [${moduleName}]`;

const header = [
  { id: 'appointmentTypes', label: 'Appointment type' },
  { id: 'intakePlan', label: 'Intake plan' },
  { id: 'edit', label: '' },
];

const initialFieldValues = {
  appointmentType: null,
  intakePlan: null,
};

export default function AthenaAppointmentTypeConfigurationModal(props) {
  const {
    isOpen, handleClose, provider, apiClient, athenaSettings, availableIntakePlans, onSave,
  } = props;

  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const { siteId } = useParams();

  const visibleHeader = useMemo(() => header, []);
  const {
    TblContainer,
    TblHead,
  } = useTable(provider, visibleHeader, { fn: (items) => items });

  const {
    values,
    setValues,
    handleInputChange,
  } = useForm(initialFieldValues);

  async function getAppointmentTypeByProviderId() {
    const { practiceId, departmentId } = athenaSettings;
    if (!practiceId || !departmentId) {
      return;
    }
    try {
      const response = await apiClient
        .getAthenaAppointmentType(siteId, practiceId, departmentId, provider.providerId);
      setAppointmentTypes(response.data);
    } catch (error) {
      setAppointmentTypes([]);
    }
  }

  useEffect(() => {
    if (athenaSettings && provider) {
      getAppointmentTypeByProviderId();
    }
  }, [athenaSettings, provider]);

  const handleSave = () => {
    const { appointmentType, intakePlan } = values;
    const { appointmenttypename, appointmenttypeid } = appointmentType;
    const typeInfo = {
      name: appointmenttypename,
      intakePlanId: intakePlan.id,
      intakePlanName: intakePlan.customerDisplayName,
    };

    const { providerId, ...providerInfo } = provider;
    providerInfo.appointmentTypes[appointmenttypeid] = typeInfo;
    const originalSetting = JSON.parse(athenaSettings.providers);
    originalSetting[providerId] = providerInfo;
    onSave('athenaProviders', 'api', JSON.stringify(originalSetting));
    setValues({});
  };

  const handleDelete = (typeId) => {
    const { providerId, ...providerInfo } = provider;
    delete providerInfo.appointmentTypes[typeId];
    const originalSetting = JSON.parse(athenaSettings.providers);
    originalSetting[providerId] = providerInfo;
    onSave('athenaProviders', 'api', JSON.stringify(originalSetting));
  };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={() => {
        handleClose();
        setValues({});
      }}
      size="sm"
      title="Appointment type configuration"
    >
      <Form>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          rowSpacing={1}
        >
          <Controls.Select
            sx={{ maxWidth: '200px' }}
            name="appointmentType"
            label="Please select appointment type"
            value={values.appointmentType}
            onChange={handleInputChange}
          >
            {appointmentTypes.length > 0 ? appointmentTypes.map((obj) => (
              <MenuItem key={obj.appointmenttypeid} value={obj}>
                {obj.appointmenttypename}
              </MenuItem>
            )) : <em> No appointment types found</em>}
          </Controls.Select>
          <Controls.Select
            sx={{ maxWidth: '200px' }}
            name="intakePlan"
            label="Please select intake plan"
            value={values.intakePlanId}
            onChange={handleInputChange}
          >
            {availableIntakePlans && availableIntakePlans.map((plan) => (
              <MenuItem key={plan.id} value={plan}>
                {makeIntakePlanLabel(plan.referenceCode, plan.moduleName)}
              </MenuItem>
            ))}
          </Controls.Select>
          <Controls.Button
            text="save"
            onClick={handleSave}
          />
        </Grid>
      </Form>
      <TblContainer>
        <TblHead />
        {provider && provider.appointmentTypes
          && Object.entries(provider.appointmentTypes).map(([id, info]) => (
            <TableRow>
              <TableCell>{`${id} ${info.name}`}</TableCell>
              <TableCell>{`${info.intakePlanId}`}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleDelete(id)}>
                  <TrashIcon className="table-tool-icon" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
      </TblContainer>

    </Modal>
  );
}

AthenaAppointmentTypeConfigurationModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  apiClient: PropTypes.shape({
    getAthenaAppointmentType: PropTypes.func.isRequired,
    getIntakePlans: PropTypes.func.isRequired,
  }).isRequired,
  provider: PropTypes.shape({
    providerId: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    daysBeforeAppointment: PropTypes.string,
    appointmentTypes: PropTypes.objectOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        intakePlanId: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  athenaSettings: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    departmentId: PropTypes.string,
    practiceId: PropTypes.string,
    providers: PropTypes.string,
    intakePlans: PropTypes.string,
  }).isRequired,
  availableIntakePlans: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    referenceCode: PropTypes.string,
    moduleName: PropTypes.string,
    customerDisplayName: PropTypes.string,
    description: PropTypes.string,
    moduleId: PropTypes.number,
  })).isRequired,
};
