import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { SketchPicker } from 'react-color';
import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';

export default function ColorPickerSetting({
  setting, refreshParent, setErrorMessage, setSuccessMessage, contextType,
}) {
  const { siteId, customerId } = useParams();
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(setting.value);
  const apiClient = useApiClient();

  const handleChange = async (newColor) => {
    if (!newColor || newColor.hex === color) {
      return;
    }
    const newColorValue = newColor.hex;
    setColor(newColorValue);
    try {
      if (contextType === 'customer') {
        await apiClient.updateCustomer(customerId, { ...setting, value: newColorValue });
      } else {
        await apiClient.updateSiteSetting(siteId, { ...setting, value: newColorValue });
      }
      setSuccessMessage('Color updated successfully');
      refreshParent();
    } catch (error) {
      setErrorMessage(convertAxiosErrorToUserFacingMessage(error));
    }
  };

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  // This provides keyboard accessibility to the color picker
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleClick();
    }
  };

  useEffect(() => {
    setColor(setting.value);
  }, [setting.value]);

  const styles = {
    color: {
      width: '36px',
      height: '20px',
      borderRadius: '2px',
      background: color,
    },
    swatch: {
      padding: '5px',
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
    },
    popover: {
      position: 'absolute',
      zIndex: '2',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
  };

  return (
    <div>
      {setting.value && (
        <div
          style={styles.swatch}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          role="button"
          tabIndex={0}
          aria-label="Open color picker"
        >
          <div style={styles.color} />
        </div>
      )}
      {displayColorPicker ? (
        <div style={styles.popover}>
          <div
            style={styles.cover}
            onClick={() => setDisplayColorPicker(false)}
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex={0}
            aria-label="Close color picker"
          />
          <SketchPicker
            color={color}
            onChange={(newColor) => setColor(newColor.hex)}
            onChangeComplete={handleChange}
          />
        </div>
      ) : null }
    </div>
  );
}

ColorPickerSetting.propTypes = {
  setting: PropTypes.shape({
    name: PropTypes.string,
    category: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  refreshParent: PropTypes.func,
  setErrorMessage: PropTypes.func,
  setSuccessMessage: PropTypes.func,
  contextType: PropTypes.oneOf(['site', 'customer']).isRequired,
};

ColorPickerSetting.defaultProps = {
  refreshParent: () => {},
  setErrorMessage: () => {},
  setSuccessMessage: () => {},
};
