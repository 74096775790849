import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Switch } from '@mui/material';
import basicServiceHandler from '../../services/basicServiceHandler';
import { convertAxiosErrorToUserFacingMessage, useApiClient } from '../../context/ApiClientContext';

export default function ToggleSwitchSetting(props) {
  const { siteId, customerId } = useParams();
  const {
    setting,
    refreshParent,
    setErrorMessage,
    setSuccessMessage,
    defaultOption,
    contextType,
  } = props;
  const apiClient = useApiClient();
  const [switchValue, setSwitchValue] = useState(setting.value ? setting.value === 'true' : defaultOption.value);

  const handleSwitchChange = (event) => {
    const { checked } = event.target;
    const updateFunction = contextType === 'customer'
      ? () => apiClient.updateCustomer(customerId, {
        name: setting.name,
        category: setting.category,
        value: event.target.checked.toString(),
      })
      : () => apiClient.updateSiteSetting(siteId, {
        name: setting.name,
        category: setting.category,
        value: event.target.checked.toString(),
      });

    basicServiceHandler(
      updateFunction,
      () => {
        setSwitchValue(checked);
        refreshParent();
        setSuccessMessage('Successfully updated setting');
      },
      (error) => {
        setErrorMessage(convertAxiosErrorToUserFacingMessage(error));
      },
    );
  };

  useEffect(() => {
    setSwitchValue(setting.value === 'true');
  }, [setting.value]);

  return (
    <Switch
      checked={switchValue}
      onChange={handleSwitchChange}
      name={setting.name}
    />
  );
}

ToggleSwitchSetting.propTypes = {
  setting: PropTypes.shape({
    name: PropTypes.string,
    category: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  refreshParent: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  contextType: PropTypes.oneOf(['site', 'customer']).isRequired,
  defaultOption: PropTypes.shape({
    value: PropTypes.bool,
  }),
};

ToggleSwitchSetting.defaultProps = {
  defaultOption: false,
};
